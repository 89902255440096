import React, { useEffect, useState } from 'react'
import { useFinanceService } from '../../../service/finance-service'
import { TablePaginationCustom, useTable } from '../../../components/table'
import { useSnackbar } from 'notistack'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHeadCustom from '../../../components/table/table-head-custom'
import TableBody from '@mui/material/TableBody'
import IyzicoPaymentRow from './market-online-payment-row'
import { IyzicoPaymentDetail } from '../../../service/types/finance'

const paymentHeadLabel = [
    { id: 'orderId', label: 'Sipariş ID' },
    { id: 'amount', label: 'Miktar' },
    { id: 'paymentId', label: 'Ödeme ID' },
    { id: 'paymentDate', label: 'Ödeme Tarihi' },
]

type IyzicoPaymentsViewProps = {
    start: number;
    end: number;
    marketId: string;
    onLoading: (loading: boolean) => void;
};

export default function MarketOnlinePaymentTable({ start, end, marketId, onLoading }: IyzicoPaymentsViewProps) {
    const financeService = useFinanceService()
    const table = useTable()
    const [payments, setPayments] = useState<IyzicoPaymentDetail[]>([])
    const [totalItems, setTotalItems] = useState(0)
    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        table.setPage(0)
    }, [start, end, marketId, table.rowsPerPage])

    useEffect(() => {
        const fetch = async () => {
            await fetchPayments()
        }
        void fetch()
    }, [table.page])

    async function fetchPayments() {
        onLoading(true)
        await financeService.getMarketIyzicoPayments(marketId, start, end, table.page, table.rowsPerPage)
            .then((page) => {
                setPayments(page.content)
                setTotalItems(page.totalElements)
            })
            .catch(() => {
                enqueueSnackbar('Ödemeler yüklenemedi', { variant: 'error' })
                setPayments([])
            })
            .finally(() => {
                onLoading(false)
            })
    }

    return <>
        <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                <TableHeadCustom
                    order={table.order}
                    orderBy={table.orderBy}
                    headLabel={paymentHeadLabel}
                    rowCount={payments.length}
                    numSelected={table.selected.length}
                    onSort={table.onSort}
                />

                <TableBody>
                    {payments.map((payment) => <IyzicoPaymentRow key={payment.paymentId} payment={payment} />)}
                </TableBody>
            </Table>
        </TableContainer>
        <TablePaginationCustom
            count={totalItems}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            dense={table.dense}
            onChangeDense={table.onChangeDense}
            labelRowsPerPage="Sayfa başına satır:"
        />
    </>
}