import {
    AutocompleteInput,
    BooleanInput,
    Button,
    ChipField,
    Create,
    Datagrid,
    Edit,
    EditButton,
    email,
    Filter,
    List,
    NumberField,
    NumberInput,
    ReferenceArrayField,
    ReferenceArrayInput,
    ReferenceField,
    ReferenceInput,
    required,
    SelectArrayInput,
    SelectInput,
    SimpleForm,
    SingleFieldList,
    TextField,
    TextInput,
    useListContext,
} from 'react-admin'
import React from 'react'
import { ListPagination } from '../../common/list-pagination'
import PasswordField from '../../common/password-field'
import Icon from '@mui/icons-material/Business'
import Grid from '@mui/material/Grid'
import { CustomSelectInput } from '../../components/ra-admin/select-input-override'
import { ShowMarketButton } from '../../common/show-market-button'
import ImageUploadInput from '../../common/image-upload-input'
import SaveOnlyToolbar from '../../common/save-only-toolbar'
import { DefaultListActions } from '../../common/default-list-action'
import { useNavigate } from 'react-router-dom'

export const CompanyIcon = Icon

const EditForm = ({ edit }: { edit: boolean }) => <SimpleForm toolbar={<SaveOnlyToolbar />}>
    <Grid container columnSpacing={2} rowSpacing={1}>
        <Grid item xs={12} md={3} xl={2}>
            <TextInput fullWidth name={'username'}
                       helperText={'Market giriş yaparken kullanılacak. Lutfen dikkatli ve dogru e-posta giriniz.'}
                       source={'username'} validate={[required(), email()]} />
        </Grid>

        <Grid item md={9} xl={10}>

        </Grid>
        <Grid item xs={12} md={3} xl={2}>
            <TextInput fullWidth name={'name'} source={'name'} validate={required()} />
        </Grid>
        <Grid item xs={12} md={3} xl={2}>
            <NumberInput fullWidth name={'commission'} source={'commission'} validate={required()} />
        </Grid>
        <Grid item md={6} xl={8}>

        </Grid>
        <Grid item xs={12} md={3} xl={2}>
            <ReferenceInput name={'city'} source={'city'} reference={'cities'}>
                <CustomSelectInput name={'city'} fullWidth
                                   disabled={edit}
                                   optionText={'name'} source={'city'}
                                   validate={required()} />
            </ReferenceInput>
        </Grid>
        <Grid item xs={12} md={3} xl={2}>
            <TextInput fullWidth name={'address'} source={'address'} validate={required()} />
        </Grid>
        <Grid item xs={12} md={3} xl={2}>
            <TextInput fullWidth name={'phoneNumber'} source={'phoneNumber'} validate={required()} />
        </Grid>
        <Grid item md={3} xl={6}>
        </Grid>
        <Grid item xs={12} md={3} xl={2}>
            <ReferenceInput name={'category'} source={'category'} reference={'categories'}>
                <AutocompleteInput name={'category'}
                                   filterToQuery={searchText => ({ name: { turkish: searchText } })}
                                   optionText={'name.turkish'}
                                   source={'category'}
                                   fullWidth
                                   size={'small'}
                                   validate={required()} />
            </ReferenceInput>
        </Grid>
        <Grid item md={12} xs={12} xl={12}>
            <ReferenceArrayInput name={'paymentTypes'} source={'paymentTypes'}
                                 perPage={100000}
                                 reference={'payment-types'}>
                <SelectArrayInput name={'paymentTypes'}
                                  fullWidth
                                  label={'Ödeme Tipleri'}
                                  validate={required()}
                                  source={'paymentTypes'} optionText={'name'} />
            </ReferenceArrayInput>
        </Grid>
        <Grid item md={12} xl={12}>
            <ImageUploadInput source={'image'} required={true} sx={{}} />
        </Grid>
        <Grid item xs={12} md={3} xl={2}>
            <BooleanInput source={'enabled'} name={'enabled'}
                          helperText={'Off oldu zamanda, Market sistemi giriş yapamaz'} />
        </Grid>
    </Grid>
</SimpleForm>
export const CompanyCreate = () => {
    return <Create redirect={'list'}>
        <EditForm edit={false} />
    </Create>
}
export const CompanyEdit = () => {
    return <Edit redirect={'list'}>
        <EditForm edit={true} />
    </Edit>
}

const CompanyFilter = (props: any) => (
    <Filter {...props}>
        <TextInput name={'name'} source={'name'} alwaysOn />
        <TextInput name={'username'} source={'username'} />
        <TextInput name={'phoneNumber'} source={'phoneNumber'} />
        <ReferenceInput label={'Kategori'} name={'category'} source={'category'} reference={'categories'} alwaysOn>
            <SelectInput optionText={'name.turkish'} />
        </ReferenceInput>
        <ReferenceInput name={'city'} source={'city'} reference={'cities'}>
            <SelectInput optionText={'name'} />
        </ReferenceInput>
    </Filter>
)

const CompanyListActions = () => {
    const navigate = useNavigate()
    const { filterValues } = useListContext()
    const category = filterValues?.category
    return <DefaultListActions>
        <>
            <Button onClick={() => {
                navigate('/companies/rearrange', { state: { category } })
            }} label={'Sıralama'} startIcon={<Icon />} />
        </>
    </DefaultListActions>
}
export const CompanyList = () => {
    return <List sort={{ field: 'id', order: 'DESC' }}
                 actions={<CompanyListActions />}
                 filters={<CompanyFilter />}
                 pagination={<ListPagination />}
                 perPage={50}>
        <Datagrid bulkActionButtons={false}>
            <TextField source={'name'} />
            <TextField source={'username'} />
            <PasswordField source={'password'} />
            <ReferenceField reference={'categories'} source={'category'}>
                <TextField source={'name.turkish'} />
            </ReferenceField>
            <NumberField source={'commission'} />
            <TextField source={'phoneNumber'} />
            <TextField source={'address'} />
            <ReferenceArrayField reference={'payment-types'} source={'paymentTypes'}>
                <SingleFieldList>
                    <ChipField source={'name'} />
                </SingleFieldList>
            </ReferenceArrayField>
            <ReferenceField reference={'cities'} source={'city'}>
                <TextField source={'name'} />
            </ReferenceField>
            <ShowMarketButton />
            <EditButton />
        </Datagrid>
    </List>
}