import React from 'react'
import {
    CloneButton,
    Create,
    Datagrid,
    DeleteButton,
    ImageField,
    List,
    maxLength,
    required,
    Show,
    ShowButton,
    SimpleShowLayout,
    TabbedForm,
    TextField,
    TextInput,
} from 'react-admin'
import { ListPagination } from '../../common/list-pagination'
import { TimeField } from '../../common/time-field'
import ImageUploadInput from '../../common/image-upload-input'
import Icon from '@mui/icons-material/NotificationAddRounded'

export const NotificationIcon = Icon

export const NotificationsList = () => (
    <List sort={{ field: 'createdAt', order: 'DESC' }} exporter={false} perPage={50} pagination={<ListPagination />}>
        <Datagrid bulkActionButtons={false}>
            <TextField source={'title.turkish'} />
            <TextField source={'body.turkish'} />
            <TimeField source={'createdAt'} />
            <CloneButton />
            <ShowButton />
            <DeleteButton />
        </Datagrid>
    </List>
)

export const NotificationCreate = () => (
    <Create>
        <TabbedForm syncWithLocation={false}>
            <TabbedForm.Tab label={'Text'}>
                <TextInput source={'title.english'} validate={[required(), maxLength(255)]} />

                <TextInput source={'title.turkish'} validate={[maxLength(255), required()]} />

                <TextInput source={'body.english'} fullWidth minRows={2} multiline validate={[required(), maxLength(1000000)]} />
                <TextInput source={'body.turkish'} fullWidth multiline minRows={2} validate={[maxLength(1000000), required()]} />
                <ImageUploadInput source={'image'} required={false} sx={{}} />

            </TabbedForm.Tab>
        </TabbedForm>
    </Create>
)

export const NotificationShow = () => (
    <Show>
        <SimpleShowLayout>
            <TextField source={'title.english'} />
            <TextField source={'title.turkish'} />
            <TextField source={'body.english'} />
            <TextField source={'body.turkish'} />
            <ImageField source={'image'} />
        </SimpleShowLayout>
    </Show>
)
