import React from 'react'
import { TableCell, TableRow } from '@mui/material'
import { IyzicoPaymentDetail } from 'src/service/types/finance'
import { fDateTime } from '../../../utils/format-time'

type IyzicoPaymentRowProps = {
    payment: IyzicoPaymentDetail;
};

const IyzicoPaymentRow = ({ payment }: IyzicoPaymentRowProps) => {
    return (
        <TableRow>
            <TableCell>{payment.orderId}</TableCell>
            <TableCell>{payment.amount.toFixed(2)}</TableCell>
            <TableCell>{payment.paymentId}</TableCell>
            <TableCell>{fDateTime(payment.paymentDate)}</TableCell>
        </TableRow>
    )
}

export default IyzicoPaymentRow