import {
    Datagrid,
    DateField,
    Filter,
    List,
    NumberField,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    TextField,
    TextInput,
    useRecordContext,
} from 'react-admin'
import React from 'react'
import { ListPagination } from '../../common/list-pagination'
import Icon from '@mui/icons-material/ShoppingBasket'
import { OrderStatusLabel, PaymentTypeLabel } from '../customer/order-row'

export const OrderIcon = Icon

const OrderFilter = (props: any) => (
    <Filter {...props}>
        <TextInput name={'customerPhone'} source={'customerPhone'} alwaysOn />
        <TextInput name={'id'} source={'id'} alwaysOn label={'ID'} />
        <ReferenceInput name={'marketId'} source={'marketId'} reference={'companies'} alwaysOn>
            <SelectInput optionText={'name'} label={'resources.companies.name'} />
        </ReferenceInput>
        <ReferenceInput name={'branchId'} source={'branchId'} reference={'branches'} alwaysOn>
            <SelectInput optionText={'name'} label={'resources.branches.name'} />
        </ReferenceInput>
    </Filter>
)

export const OrdersList = () => {
    return <List sort={{ field: 'id', order: 'DESC' }}
                 filters={<OrderFilter />}
                 exporter={false}
                 storeKey={false}
                 pagination={<ListPagination />}
                 perPage={50}>
        <Datagrid bulkActionButtons={false}>
            <TextField source={'id'} label={'#'} />
            <DateField source={'pendingTime'} showTime label={'Siparis Tarihi'} />
            <RecordOrderStatusLabel label={'Siparis Durum'} />
            <RecordPaymentTypeLabel label={'Odeme Tip'} />
            <NumberField source={'finalPrice'} label={'Fiyat (TRY)'} />
            <ReferenceField reference={'customers'} source={'customer'}>
                <TextField source={'name'} />
            </ReferenceField>
            <ReferenceField reference={'companies'} source={'marketId'} label={'resources.companies.name'}>
                <TextField source={'name'} />
            </ReferenceField>
            <ReferenceField reference={'branches'} source={'marketId'} label={'resources.branches.name'}>
                <TextField source={'name'} />
            </ReferenceField>
        </Datagrid>
    </List>
}

const RecordOrderStatusLabel = ({ label }: { label: string }) => {
    const record = useRecordContext()
    if (!record) return <></>
    return <OrderStatusLabel status={record.status} />
}

const RecordPaymentTypeLabel = ({ label }: { label: string }) => {
    const record = useRecordContext()
    if (!record) return <></>
    return <PaymentTypeLabel type={record.paymentType} />
}