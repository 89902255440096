import ShowIcon from '@mui/icons-material/VisibilityRounded'
import CreateIcon from '@mui/icons-material/CreateRounded'
import EditIcon from '@mui/icons-material/EditRounded'
import { type BreadItem } from './global-context'
import { type FC } from 'react'
import { CityIcon } from '../../features/address/city'
import { DistrictIcon } from '../../features/address/district'
import { TownIcon } from '../../features/address/town'
import { BranchIcon } from '../../features/branch/branch'
import { CategoryIcon } from '../../features/category/category'
import { CustomerIcon } from '../../features/customer/customer'
import { NotificationIcon } from '../../features/notification/notification'
import { AppBannerIcon } from '../../features/banners/app-banner'
import SequenceIcon from '@mui/icons-material/SwapVertRounded'
import { OrderIcon } from '../../features/orders/orders'
import { FinanceIcon } from '../../features/finance/finance-view'


export interface Path {
    id: string
    title: string
    translate: boolean
    icon?: FC<any>
}

const paths: Path[] = [
    {
        id: 'cities',
        title: 'resources.cities.name',
        icon: CityIcon,
        translate: true,
    },
    {
        id: 'districts',
        title: 'resources.districts.name',
        translate: true,
        icon: DistrictIcon,
    },
    {
        id: 'towns',
        title: 'resources.towns.name',
        translate: true,
        icon: TownIcon,
    },
    {
        id: 'branches',
        title: 'resources.branches.name',
        translate: true,
        icon: BranchIcon,
    },
    {
        id: 'companies',
        title: 'resources.companies.name',
        translate: true,
        icon: BranchIcon,
    },
    {
        id: 'categories',
        title: 'resources.categories.name',
        translate: true,
        icon: CategoryIcon,
    },
    {
        id: 'show',
        title: 'ra.action.show',
        icon: ShowIcon,
        translate: true,
    },
    {
        id: 'create',
        title: 'ra.action.create',
        icon: CreateIcon,
        translate: true,
    },
    {
        id: 'edit',
        title: 'ra.action.edit',
        icon: EditIcon,
        translate: true,
    },
    {
        id: 'customers',
        title: 'resources.customers.name',
        icon: CustomerIcon,
        translate: true,
    },
    {
        id: 'notifications',
        title: 'resources.notifications.name',
        icon: NotificationIcon,
        translate: true,
    },
    {
        id: 'app-banners',
        title: 'resources.app-banners.name',
        translate: true,
        icon: AppBannerIcon,
    },
    {
        id: 'rearrange',
        title: 'Sıralama',
        icon: SequenceIcon,
        translate: true,
    },
    {
        id: 'orders',
        title: 'resources.orders.name',
        translate: true,
        icon: OrderIcon,
    },
    {
        id: 'finance',
        title: 'Finans',
        translate: false,
        icon: FinanceIcon,
    },
]

export function getBreadMatch(pathId: string, end: boolean, displays: Record<string, BreadItem> | undefined): Path {
    let display: BreadItem | undefined
    if (end) {
        display = displays?.[`${pathId}tab`]
    }
    if (!display) {
        display = displays?.[pathId]
    }
    return (
        paths.find((x) => x.id === pathId) ?? {
            id: pathId,
            title: display?.title ?? pathId,
            icon: display?.icon,
            translate: false,
        }
    )
}
