import { ApiAuthProvider } from '../service/api-auth-provider'
import { AuthProvider } from 'ra-core'


export default function AuthClient(): AuthProvider {
    return {
        login: async () => {

        },
        logout: async () => {
            ApiAuthProvider.INSTANCE.setSession(undefined)
        },
        checkAuth: async () => {
            const session = ApiAuthProvider.INSTANCE.getSession()
            if (session) {
                return
            }
            throw new Error('Lutfen tekrar giris yapin')
        },
        checkError: async ({ status }) => {
            if (status === 401 || status === 403) {
                ApiAuthProvider.INSTANCE.setSession(undefined)
            } else if (ApiAuthProvider.INSTANCE.getSession()) {
                return
            }
            throw new Error('Lutfen tekrar giris yapin')
        },
        getPermissions: async () => {
            const user = ApiAuthProvider.INSTANCE.getSession()
            if (user) {
                return user.authority
            }
            throw new Error('Lutfen tekrar giris yapin')
        },
    }
}


