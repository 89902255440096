export const AppBannerTranslations = {
    tr: {
        'app-banners': {
            name: 'Uygulama Bannerları',
            fields: {
                image: 'Resim',
                sequence: 'Sıra',
                createdAt: 'Oluşturulis Tarih',
                updatedAt: 'Güncellen Tarih',
            },
        },
    },
}