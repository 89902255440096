import React, { useEffect, useState } from 'react'
import { InputAdornment, Popover, TextField } from '@mui/material'
import { addDays } from 'date-fns'
import { DateRangePicker } from 'react-date-range'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import trLocale from 'date-fns/locale/tr'
import makeStyles from '@mui/styles/makeStyles'
import DateRangeIcon from '@mui/icons-material/DateRange'
import { useTranslate } from 'react-admin'
import DateInputThemeSelector from './DateInputThemeSelector'
import { useFormContext } from 'react-hook-form'
import { useTheme } from '@mui/styles'
import { fDate } from '../../utils/format-time'

const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.background.paper,
        height: '421px',
    },
    calender: {
        overflowY: 'scroll',
    },
    icon: {
        color: theme.palette.text.secondary,
    },

    time: {
        backgroundColor: theme.palette.mode === 'dark' ? '#000' : '#FFF',
        height: '100%',
        display: 'flex',
        padding: '1em',
        flexDirection: 'column',
        alignContent: 'center',
        alignItems: 'center',
    },
}))

export function DateRangeInput({ onChange, insideFilter, value, ...others }) {
    useEffect(() => {
        if (value && value.start && value.end) {
            setTimeout(() => {
                const update = {
                    startDate: new Date(value.start),
                    endDate: new Date(value.end),
                    key: 'selection',
                }
                setState([update])
                updateText(update)
            }, 500)
        } else if (state && state[0]) {
            updateText(state[0])
        }
    }, [value])
    const [state, setState] = useState([
        {
            startDate: addDays(new Date(), -7),
            endDate: new Date(),
            key: 'selection',
        },
    ])
    const translate = useTranslate()
    const [anchorEl, setAnchorEl] = useState(null)
    const [text, setText] = useState('')
    const theme = useTheme()

    const classes = useStyles()

    function onDateChange(item) {
        let selection = item.selection
        setState([selection])
        updateText(selection)
        onChange({
            start: selection.startDate.getTime(),
            end: selection.endDate.getTime(),
        })
    }

    function updateText(selection) {
        let start = selection.startDate
        let end = selection.endDate
        if (end === start) {
            end = new Date(end)
        }
        let s = fDate(start)
        let s1 = fDate(end)
        setText(`${s} ─ ${s1}`)
    }

    useEffect(() => {
        if (state[0] && !value) {
            onChange({
                start: state[0].startDate.getTime(),
                end: state[0].endDate.getTime(),
            })
        }
    }, [state])

    return (
        <DateInputThemeSelector>
            <TextField
                label={translate('dateRangeInput.title')}
                value={text}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position={'start'}>
                            <DateRangeIcon className={classes.icon} />
                        </InputAdornment>
                    ),
                }}
                onClick={(e) => setAnchorEl(e.currentTarget)}
                {...others}
            />
            <Popover
                open={anchorEl != null}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <div className={classes.root}>
                    <DateRangePicker
                        className={classes.calendar}
                        onChange={onDateChange}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={1}
                        rangeColors={[theme.palette.primary.main]}
                        locale={trLocale}
                        ranges={state}
                        direction="horizontal"
                    />
                </div>
            </Popover>
        </DateInputThemeSelector>
    )
}

const Nothing = () => {
    return <></>
}

export const FormDateRangeInput = ({ source, required, name, ...props }) => {
    const { trigger, setValue, getValues } = useFormContext()
    return (
        <DateRangeInput
            insideFilter
            value={getValues(source)}
            onChange={(value) => {
                setValue(source, value)
                trigger(source)
            }}
            {...props}
        />
    )
}
export const DateRangeInputTranslations = {
    tr: {
        dateRangeInput: {
            title: 'Tarih aralığı seçin',
            close: 'Kapat',
            apply: 'Uygula',
        },
    },
}
