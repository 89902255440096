import React from 'react'
import { Datagrid, Filter, List, Show, ShowButton, Tab, TabbedShowLayout, TextField, TextInput } from 'react-admin'
import Icon from '@mui/icons-material/PeopleAltRounded'
import UserAddressesView from './user-addresses-view'
import UserOrdersView from './user-orders-view'
import { TimeField } from '../../common/time-field'

export const CustomerIcon = Icon

const CustomerFilter = (props: any) => (
    <Filter {...props}>
        <TextInput label="Ara" name={'name'} source="name" alwaysOn />
    </Filter>
)

export const CustomersList = () => {
    return <List exporter={false} filters={<CustomerFilter />}>
        <Datagrid bulkActionButtons={false}>
            <TextField source="name" />
            <TextField source="phone" />
            <TextField source="email" />
            <TimeField source="createdAt" />
            <ShowButton />
        </Datagrid>
    </List>
}

export const CustomerShow = () => {
    return <Show>
        <TabbedShowLayout syncWithLocation={false}>
            <Tab label={'Genel'}>
                <TextField source="name" />
                <TextField source="phone" />
                <TextField source="email" />
                <TimeField source="createdAt" />
            </Tab>
            <Tab label={'Adresler'}>
                <UserAddressesView />
            </Tab>
            <Tab label={'Siparişler'}>
                <UserOrdersView />
            </Tab>
        </TabbedShowLayout>
    </Show>
}