import { FormProvider as Form, UseFormReturn } from 'react-hook-form'
import React from 'react'

type Props = {
    children: React.ReactNode
    methods: UseFormReturn<any>
    onSubmit?: VoidFunction
    formRef?: React.Ref<HTMLFormElement>
}

export default function FormProvider({ children, onSubmit, formRef, methods }: Props) {
    return (
        <Form {...methods}>
            <form ref={formRef} onSubmit={onSubmit}>
                {children}
            </form>
        </Form>
    )
}
