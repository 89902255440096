import {
    Button,
    Create,
    Datagrid,
    DateField,
    Edit,
    EditButton,
    List,
    required,
    SimpleForm,
    TextField,
    TextInput,
} from 'react-admin'
import React from 'react'
import { ListPagination } from '../../common/list-pagination'
import Icon from '@mui/icons-material/CategoryRounded'
import Grid from '@mui/material/Grid'
import ImageUpload from '../../common/image-upload-input'
import { DefaultListActions } from '../../common/default-list-action'
import { useNavigate } from 'react-router-dom'

export const CategoryIcon = Icon

const EditForm = () => <SimpleForm>
    <Grid container columnSpacing={2} rowSpacing={1}>
        <Grid item xs={12} md={3} xl={2}>
            <TextInput fullWidth name={'name.turkish'} source={'name.turkish'} validate={required()} />
        </Grid>
        <Grid item xs={12} md={3} xl={2}>
            <TextInput fullWidth name={'name.english'} source={'name.english'} validate={required()} />
        </Grid>
        <Grid item xs={12} md={12} xl={12}>
            <ImageUpload source={'image'} sx={{}} required={true} />
        </Grid>
    </Grid>
</SimpleForm>
export const CategoryCreate = () => {
    return <Create redirect={'list'}>
        <EditForm />
    </Create>
}
export const CategoryEdit = () => {
    return <Edit redirect={'list'}>
        <EditForm />
    </Edit>
}

export const CategoryList = () => {
    const navigate = useNavigate()
    return <List sort={{ field: 'id', order: 'DESC' }}
                 actions={<DefaultListActions>
                     <>
                         <Button onClick={() => {
                             navigate('/categories/rearrange')
                         }} label={'Sıralama'} startIcon={<Icon />} />
                     </>
                 </DefaultListActions>}
                 pagination={<ListPagination />} perPage={50}>
        <Datagrid bulkActionButtons={false}>
            <TextField source={'name.turkish'} />
            <TextField source={'name.english'} />
            <DateField source={'createdAt'} showTime />
            <EditButton />
        </Datagrid>
    </List>
}