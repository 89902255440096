import React from 'react'
import { Button, TableCell, TableRow } from '@mui/material'
import { IyzicoPayment } from 'src/service/types/finance'
import { MarketTypeLabel } from './market-commission-row'
import Iconify from 'src/components/iconify'

type IyzicoPaymentRowProps = {
    payment: IyzicoPayment;
    onDetail: (marketId: string, title: string) => void;
};

const IyzicoPaymentRow = ({ payment, onDetail }: IyzicoPaymentRowProps) => {
    return (
        <TableRow>
            <TableCell>{payment.name}</TableCell>
            <TableCell>{payment.amount.toFixed(2)}</TableCell>
            <TableCell>{payment.orders}</TableCell>
            <TableCell><MarketTypeLabel marketType={payment.marketType} /></TableCell>
            <TableCell><Button variant={'contained'}
                               onClick={() => {
                                   onDetail(payment.id, payment.name)
                               }}
                               color={'primary'} size={'small'} startIcon={<Iconify
                icon={'solar:info-circle-bold-duotone'} />}>Detay</Button></TableCell>
        </TableRow>
    )
}

export default IyzicoPaymentRow