import {
    BooleanField,
    BooleanInput,
    Create,
    Datagrid,
    DeleteWithConfirmButton,
    Edit,
    EditButton,
    ImageField,
    List,
    NumberInput,
    SimpleForm,
    TextField,
} from 'react-admin'
import React from 'react'
import { TimeField } from '../../common/time-field'
import ImageUploadInput from '../../common/image-upload-input'
import Icon from '@mui/icons-material/PhotoCameraRounded'

export const AppBannerIcon = Icon

export default function AppBannerListView() {
    return <List exporter={false} sort={{ field: 'sequence', order: 'ASC' }}>
        <Datagrid bulkActionButtons={false}>
            <ImageField source="image" />
            <TextField source="sequence" />
            <BooleanField source="active" />
            <TimeField source="createdAt" />
            <TimeField source="updatedAt" />
            <EditButton />
            <DeleteWithConfirmButton />
        </Datagrid>
    </List>
}

const AppBannerCreateEditView = () => {
    return <SimpleForm>
        <ImageUploadInput source="image" required={true} sx={{ mb: 2 }} />
        <NumberInput source="sequence" name={'sequence'} />
        <BooleanInput name={'active'} source={'active'} />
    </SimpleForm>
}

export function AppBannerCreateView() {
    return <Create>
        <AppBannerCreateEditView />
    </Create>
}

export function AppBannerEditView() {
    return <Edit>
        <AppBannerCreateEditView />
    </Edit>
}