export const CompanyTranslations = {
    tr: {
        companies: {
            name: 'İşletmeler',
            fields: {
                username: 'E-posta',
                password: 'Şifre',
                city: 'Şehir',
                phoneNumber: 'Telefon Numarası',
                address: 'Adres',
                commission: 'Komisyon',
                id: '#',
                name: 'Adı',
                category: 'Kategori',
                paymentTypes: 'Ödeme Tipleri',
            },
        },
    },
}