import React from 'react'
import Icon from '@mui/icons-material/Apartment'
import {
    BooleanField,
    BooleanInput,
    Create,
    Datagrid,
    Edit,
    EditButton,
    List,
    ReferenceField,
    ReferenceInput,
    required,
    SelectInput,
    TabbedForm,
    TextField,
    TextInput,
} from 'react-admin'
import { ListPagination } from '../../common/list-pagination'
import { MapInput } from '../../common/map/map-input'

export const DistrictIcon = Icon

export const DistrictList = () => (
    <List sort={{ field: 'id', order: 'DESC' }} pagination={<ListPagination />} perPage={50}>
        <Datagrid bulkActionButtons={false}>
            <TextField source={'name'} />
            <ReferenceField source={'city'} reference={'cities'}>
                <TextField source={'name'} />
            </ReferenceField>
            <BooleanField source={'active'} />
            <EditButton />
        </Datagrid>
    </List>
)

export const DistrictCreate = () => (
    <Create redirect={'list'}>
        <CreateOrEdit />
    </Create>
)

export const DistrictEdit = () => (
    <Edit redirect={'list'}>
        <CreateOrEdit />
    </Edit>
)

const CreateOrEdit = (props) => (
    <TabbedForm {...props} redirect={'list'} syncWithLocation={false}>
        <TabbedForm.Tab label={'resources.districts.fields.location'}>
            <MapInput source={'location'} required={false} />
        </TabbedForm.Tab>
        <TabbedForm.Tab label={'resources.districts.general'}>
            <TextInput source={'name'} validate={required()} name={'name'} />
            <ReferenceInput
                source={'city'}
                name={'city'}
                perPage={1000}
                reference={'cities'}
            >
                <SelectInput optionText={'name'} validate={required()} />
            </ReferenceInput>
            <BooleanInput source={'active'} name={'active'} />
        </TabbedForm.Tab>
    </TabbedForm>
)

export const DistrictTranslations = {
    tr: {
        districts: {
            name: 'İlçeler',
            general: 'Genel',
            fields: {
                name: 'Adı',
                location: 'Konum',
                active: 'Aktif',
                city: 'Şehir',
            },
        },
    },
}
