import React, { useMemo, useState } from 'react'
import {
    closestCenter,
    DndContext,
    DragOverlay,
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import Table from '@mui/material/Table'
import { useTable } from '../../components/table'
import { DragRearrangeItemView, RearrangeItemView } from './re-arrange-item-view'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import { SequenceItem } from '../../service/types/sequence'

type Props = {
    data: SequenceItem[]
    setData: (data: SequenceItem[]) => void
}

export function RearrangeTableView({ data, setData }: Props) {
    const [activeId, setActiveId] = useState()
    const table = useTable()
    const sensors = useSensors(
        useSensor(MouseSensor, {}),
        useSensor(TouchSensor, {}),
        useSensor(KeyboardSensor, {}),
    )

    function handleDragStart(event: any) {
        setActiveId(event.active.id)
    }

    function handleDragEnd(event: any) {
        const { active, over } = event
        if (!over) {
            return
        }
        if (active.id !== over.id) {
            const oldIndex = data.findIndex((it) => it.id === active.id)
            const newIndex = data.findIndex((it) => it.id === over.id)
            const update = arrayMove(data, oldIndex, newIndex)
            setData(update)
        }
        setActiveId(undefined)
    }

    function handleDragCancel() {
        setActiveId(undefined)
    }

    const selectedRow = useMemo(() => {
        if (!activeId) {
            return undefined
        }
        return data.find(original => original.id === activeId)
    }, [activeId, data])


    return (
        <DndContext
            sensors={sensors}
            onDragEnd={handleDragEnd}
            onDragStart={handleDragStart}
            onDragCancel={handleDragCancel}
            collisionDetection={closestCenter}
            modifiers={[restrictToVerticalAxis]}
        >
            <TableContainer sx={{ position: 'relative', overflowY: 'unset', maxHeight: 600, overflowX: 'auto' }}>
                <Table size={table.dense ? 'small' : 'medium'}>
                    <TableBody>
                        <SortableContext items={data.map(it => it.id)} strategy={verticalListSortingStrategy}>
                            {data.map((row) => {
                                return <DragRearrangeItemView key={row.id} item={row} />
                            })}
                        </SortableContext>
                    </TableBody>
                </Table>
            </TableContainer>
            <DragOverlay>
                {activeId && (
                    <Table size={table.dense ? 'small' : 'medium'}>
                        <TableBody>
                            {selectedRow && <RearrangeItemView item={selectedRow} />}
                        </TableBody>
                    </Table>
                )}
            </DragOverlay>
        </DndContext>
    )
}
