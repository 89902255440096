import React from 'react'
import TableRow, { TableRowProps } from '@mui/material/TableRow'
import { DragHandle } from './drag-handle'
import ListItemText from '@mui/material/ListItemText'
import TableCell from '@mui/material/TableCell'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import Stack from '@mui/material/Stack'
import { SequenceItem } from '../../service/types/sequence'

type Props = {
    item: SequenceItem,
} & TableRowProps

export function RearrangeItemView({ item }: Props) {
    return (
        <TableRow>
            <TableCell sx={{ width: 200 }}>
                <Stack spacing={1} direction={'row'} alignItems={'center'}>
                    <DragHandle isDragging={false} />
                    <ListItemText primary={item.title} secondary={item.subtitle} />
                </Stack>
            </TableCell>
        </TableRow>
    )
}

type DragProps = Props

export function DragRearrangeItemView({ item }: DragProps) {
    const {
        attributes,
        listeners,
        transform,
        transition,
        setNodeRef,
        isDragging,
    } = useSortable({
        id: item.id,
    })
    if (isDragging) {
        return (
            <TableRow sx={{
                transform: CSS.Transform.toString(transform),
                transition,
            }}>
                <TableCell colSpan={3} sx={{ backgroundColor: theme => theme.palette.background.neutral }}>
                    &nbsp;
                </TableCell>
            </TableRow>
        )
    }
    return (
        <TableRow ref={setNodeRef} sx={{ transform: CSS.Transform.toString(transform), transition }}>
            <TableCell sx={{ width: 200 }}>
                <Stack spacing={1} direction={'row'} alignItems={'center'}>
                    <DragHandle isDragging={isDragging} {...attributes} {...listeners} />
                    <ListItemText primary={item.title} secondary={item.subtitle} />
                </Stack>
            </TableCell>
        </TableRow>
    )
}