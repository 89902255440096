import { format, formatDistanceToNow, getTime } from 'date-fns'

type InputValue = Date | string | number | null | undefined

export function fDate(date: InputValue, newFormat?: string) {
    const fm = newFormat ?? 'dd MMM yyyy'

    return date ? format(new Date(date), fm) : ''
}

export function fDateTime(date: InputValue, newFormat?: string) {
    const fm = newFormat ?? 'dd.MM.yyyy HH:mm'

    return date ? format(new Date(date), fm) : ''
}

export function fTimestamp(date: InputValue) {
    return date ? getTime(new Date(date)) : ''
}

export function fToNow(date: InputValue) {
    return date
        ? formatDistanceToNow(new Date(date), {
              addSuffix: true,
          })
        : ''
}
