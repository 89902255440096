import raTr from './raTr'
import { MenuTranslations } from '../layout/dashboard/config-navigation'
import { ImageUploadTranslation } from '../common/image-upload-input'
import { CityTranslations } from '../features/address/city'
import { DistrictTranslations } from '../features/address/district'
import { TownTranslations } from '../features/address/town'
import { BranchTranslations } from '../features/branch/branch+'
import { CompanyTranslations } from '../features/company/company+'
import { CategoryTranslations } from '../features/category/category+'
import { CustomerTranslations } from '../features/customer/customer+'
import { NotificationTranslations } from '../features/notification/notification+'
import { AppBannerTranslations } from '../features/banners/app-banner+'
import { OrderTranslations } from '../features/orders/order+'
import { DateRangeInputTranslations } from '../common/date/date-range-input'

export default {
    ...raTr,
    login: {
        loginFailed: 'Giriş başarısız. Kullanıcı adını ve şifreyi kontrol ediniz',
    },
    resources: {
        ...CityTranslations.tr,
        ...TownTranslations.tr,
        ...DistrictTranslations.tr,
        ...BranchTranslations.tr,
        ...CompanyTranslations.tr,
        ...CategoryTranslations.tr,
        ...CustomerTranslations.tr,
        ...NotificationTranslations.tr,
        ...AppBannerTranslations.tr,
        ...OrderTranslations.tr,
    },
    ...MenuTranslations.tr,
    ...ImageUploadTranslation.tr,
    ...DateRangeInputTranslations.tr,
}
