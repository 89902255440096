export const CustomerTranslations = {
    tr: {
        customers: {
            name: 'Müşteriler',
            fields: {
                name: 'Isim',
                phone: 'Telefon',
                createdAt: 'Uyelik Tarihi',
                email: 'E-posta',
            },
        },
    },
}