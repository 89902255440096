import React, { useMemo } from 'react'
import { Translate, useTranslate } from 'react-admin'
import { CityIcon } from '../../features/address/city'
import { DistrictIcon } from '../../features/address/district'
import { TownIcon } from '../../features/address/town'
import LocationIcon from '@mui/icons-material/LocationOn'
import { BranchIcon } from '../../features/branch/branch'
import { CompanyIcon } from '../../features/company/company'
import { CategoryIcon } from '../../features/category/category'
import { CustomerIcon } from '../../features/customer/customer'
import { NotificationIcon } from '../../features/notification/notification'
import { AppBannerIcon } from '../../features/banners/app-banner'
import { OrderIcon } from '../../features/orders/orders'
import { FinanceIcon } from '../../features/finance/finance-view'


const locations = (translate: Translate) => [
    { title: translate('resources.cities.name'), path: '/cities', icon: <CityIcon /> },
    { title: translate('resources.districts.name'), path: '/districts', icon: <DistrictIcon /> },
    { title: translate('resources.towns.name'), path: '/towns', icon: <TownIcon /> },
]

const marketItems = (translate: Translate) => [
    { title: translate('resources.branches.name'), path: '/branches', icon: <BranchIcon /> },
    { title: translate('resources.companies.name'), path: '/companies', icon: <CompanyIcon /> },
]

const orderItems = () => [
    { title: 'Listesi', path: '/orders', icon: <OrderIcon /> },
    { title: 'Finans', path: '/finance', icon: <FinanceIcon /> },
]

const categoryItems = (translate: Translate) => [
    { title: translate('resources.categories.name'), path: '/categories', icon: <CategoryIcon /> },
]
const customerItems = (translate: Translate) => [
    { title: 'Listesi', path: '/customers', icon: <CustomerIcon /> },
    { title: translate('resources.notifications.name'), path: '/notifications', icon: <NotificationIcon /> },
]

const bannerItems = (translate: Translate) => [
    { title: 'Listesi', path: '/app-banners', icon: <AppBannerIcon /> },
]

const adminItems = (translate: Translate) => [
    {
        subheader: 'Catalog',
        items: [
            {
                title: translate('menu.categories'),
                children: categoryItems(translate),
                icon: <CategoryIcon />,
                path: '/categories',
            },
            {
                title: translate('menu.locations'),
                children: locations(translate),
                icon: <LocationIcon />,
                path: '/cities',
            },
            {
                title: translate('menu.markets'),
                children: marketItems(translate),
                icon: <BranchIcon />,
                path: '/branches',
            },
            {
                title: translate('menu.orders'),
                children: orderItems(),
                path: '/orders',
                icon: <OrderIcon />,
            }
        ],
    },
    {
        subheader: 'Management',
        items: [
            {
                title: 'Muşteriler',
                children: customerItems(translate),
                icon: <CustomerIcon />,
                path: '/customers',
            },
            {
                title: 'Uygulama Bannerları',
                path: '/app-banners',
                icon: <AppBannerIcon />,
                children: bannerItems(translate),
            },
        ],
    },
]

export function useNavData() {
    const translate = useTranslate()
    return useMemo(() => {
        return adminItems(translate)
    }, [translate])
}

export const MenuTranslations = {
    tr: {
        menu: {
            locations: 'Bölgeler',
            markets: 'Marketler',
            categories: 'Kategoriler',
            orders: 'Siparişler',
        },
    },
}
