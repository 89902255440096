import React from 'react'
import Icon from '@mui/icons-material/House'
import {
    BooleanField,
    BooleanInput,
    Create,
    Datagrid,
    Edit,
    EditButton,
    Filter,
    List,
    ReferenceField,
    ReferenceInput,
    required,
    SelectInput,
    TabbedForm,
    TextField,
    TextInput,
} from 'react-admin'
import { ListPagination } from '../../common/list-pagination'
import { MapInput } from '../../common/map/map-input'

export const TownIcon = Icon

const TownFilter = (props) => (
    <Filter {...props}>
        <TextInput label={'Ara...'} name={'name'} source={'name'} alwaysOn />
        <ReferenceInput label={'resources.towns.fields.district'} name={'district'} source={'district'} reference={'districts'} allowEmpty>
            <SelectInput optionText={'name'} name={'district'} />
        </ReferenceInput>
    </Filter>
)

export const TownList = () => (
    <List sort={{ field: 'id', order: 'DESC' }} filters={<TownFilter/>} perPage={50} pagination={<ListPagination />}>
        <Datagrid bulkActionButtons={false}>
            <TextField source={'name'} />
            <ReferenceField source={'district'} reference={'districts'}>
                <TextField source={'name'} />
            </ReferenceField>
            <BooleanField source={'active'} />
            <EditButton />
        </Datagrid>
    </List>
)

export const TownCreate = () => (
    <Create redirect={'list'}>
        <CreateOrEdit />
    </Create>
)

export const TownEdit = () => (
    <Edit redirect={'list'}>
        <CreateOrEdit />
    </Edit>
)

const CreateOrEdit = () => (
    <TabbedForm redirect={'list'} syncWithLocation={false}>
        <TabbedForm.Tab label={'resources.towns.fields.location'}>
            <MapInput source={'location'} required={true} />
        </TabbedForm.Tab>
        <TabbedForm.Tab label={'resources.towns.general'}>
            <TextInput source={'name'} name={'name'} validate={required()} />
            <ReferenceInput name={'district'} perPage={1000} source={'district'} reference={'districts'}>
                <SelectInput optionText={'name'} validate={required()} />
            </ReferenceInput>
            <BooleanInput source={'active'} name={'active'} />
        </TabbedForm.Tab>
    </TabbedForm>
)

export const TownTranslations = {
    tr: {
        towns: {
            name: 'Mahalle',
            general: 'Genel',
            fields: {
                name: 'Adı',
                location: 'Konum',
                active: 'Aktif',
                district: 'İlçe',
            },
        },
    },
}
