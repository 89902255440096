import { AxiosInstance } from 'axios'
import { useRestService } from './base-service'
import { Order, UserAddress } from './types/user'

export const useCustomerService = () => {
    const api = useRestService()
    return new CustomerService(api)
}

class CustomerService {
    private readonly api: AxiosInstance

    constructor(api: AxiosInstance) {
        this.api = api
    }

    public async getAddresses(customerId: string): Promise<UserAddress[]> {
        const response = await this.api.get<UserAddress[]>(`/customers/${customerId}/addresses`)
        return response.data
    }

    public async getOrders(customerId: string): Promise<Order[]> {
        const response = await this.api.get<Order[]>(`/customers/${customerId}/orders`)
        return response.data
    }

}
