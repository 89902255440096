export const BranchTranslations = {
    tr: {
        branches: {
            name: 'Bayiler',
            fields: {
                username: 'E-posta',
                password: 'Şifre',
                commission: 'Komisyon',
                towns: 'Mahalleler',
                city: 'Şehir',
                address: 'Adres',
                phoneNumber: 'Telefon Numarası',
                districts: 'İlçeler',
                id: '#',
                name: 'Adı',
                paymentTypes: 'Ödeme Tipleri',
            },
        },
    },
}