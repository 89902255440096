import React from 'react'
import RearrangeView from '../re-arrange/rearrange-view'
import { SequencedResource } from '../../service/types/sequence'
import { useLocation } from 'react-router-dom'

export default function CompaniesRearrangeView() {
    const { state } = useLocation()
    return (
        <RearrangeView resource={SequencedResource.companies} search={state?.category} />
    )
}