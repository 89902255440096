import React from 'react'
import { Order, OrderStatus, PaymentType } from '../../service/types/user'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { fDateTime } from '../../utils/format-time'
import Label, { LabelColor } from 'src/components/label'
import { fCurrencyTl } from '../../utils/format-number'

type Props = {
    order: Order
}

export default function OrderRow({ order }: Props) {
    return <TableRow>
        <TableCell><OrderStatusLabel status={order.status} /></TableCell>
        <TableCell>{fCurrencyTl(order.finalPrice)}</TableCell>
        <TableCell>{fDateTime(order.pendingTime)}</TableCell>
        <TableCell><PaymentTypeLabel type={order.paymentType} /></TableCell>
    </TableRow>
}

export function PaymentTypeLabel({ type }: { type: PaymentType }) {
    let color: LabelColor = 'primary'
    let text = ''
    const id = type.id
    if (id === 'CASH') {
        color = 'success'
        text = 'Nakit'
    } else if (id === 'CREDIT_CARD') {
        color = 'warning'
        text = 'Kredi Kartı'
    } else if (id === 'CREDIT_CARD_DOOR') {
        color = 'info'
        text = 'Kapıda Kredi Kartı'
    }
    return <Label color={color} variant="soft">{text}</Label>
}

export function OrderStatusLabel({ status }: { status: OrderStatus }) {
    let color: LabelColor = 'primary'
    let text = ''
    if (status === OrderStatus.PENDING) {
        color = 'warning'
        text = 'Beklemede'
    } else if (status === OrderStatus.DELIVERED) {
        color = 'success'
        text = 'Tamamlandı'
    } else if (status === OrderStatus.CANCELLED) {
        color = 'error'
        text = 'İptal Edildi'
    } else if (status === OrderStatus.CONFIRMED) {
        color = 'info'
        text = 'Onaylandı'
    } else if (status === OrderStatus.ONWAY) {
        color = 'info'
        text = 'Yolda'
    }
    return <Label color={color} variant="soft">{text}</Label>
}