import React, { useEffect, useState } from 'react'
import { useFinanceService } from '../../service/finance-service'
import { TablePaginationCustom, useTable } from '../../components/table'
import { useBoolean } from '../../hooks/use-boolean'
import { useSnackbar } from 'notistack'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHeadCustom from '../../components/table/table-head-custom'
import TableBody from '@mui/material/TableBody'
import MarketCommissionRow from './market-commission-row'
import LinearProgressView from '../../common/linear-progress-view'
import { MarketCommission } from '../../service/types/finance'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'

const commissionHeadLabel = [
    { id: 'name', label: 'Adı' },
    { id: 'orders', label: 'Tüm Siparişlar' },
    { id: 'revenue', label: 'Gelir' },
    { id: 'cost', label: 'Gider' },
    { id: 'profit', label: 'Kar' },
    { id: 'commission', label: 'Komisiyon' },
    { id: 'marketType', label: 'Market Tipi' },
]

type MarketCommissionsViewProps = {
    start: number
    end: number
}
export default function MarketCommissionsView({ start, end }: MarketCommissionsViewProps) {
    const financeService = useFinanceService()
    const table = useTable()
    const loading = useBoolean(true)
    const [commissions, setCommissions] = useState<MarketCommission[]>([])
    const [filteredCommissions, setFilteredCommissions] = useState<MarketCommission[]>([])
    const { enqueueSnackbar } = useSnackbar()
    const [search, setSearch] = useState('')

    useEffect(() => {
        const fetch = async () => {
            loading.setValue(true)
            await financeService.getMarketCommissions(start, end)
                .then(setCommissions)
                .catch(() => {
                    enqueueSnackbar('Pazar komisyonları yüklenemedi', { variant: 'error' })
                    setCommissions([])
                }).finally(loading.onFalse)
        }
        void fetch()
    }, [start, end])

    useEffect(() => {
        if (search === '') {
            setFilteredCommissions(commissions)
        } else {
            setFilteredCommissions(commissions.filter(commission => commission.name.toLowerCase().includes(search.toLowerCase())))
        }
    }, [commissions, search])

    return <>
        <Box>
            <TextField
                label="Ara"
                value={search}
                size={'small'}
                onChange={(e) => {
                    setSearch(e.target.value)
                }}
                margin="normal"
            />
        </Box>
        <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                <TableHeadCustom
                    order={table.order}
                    orderBy={table.orderBy}
                    headLabel={commissionHeadLabel}
                    rowCount={filteredCommissions.length}
                    numSelected={table.selected.length}
                    onSort={table.onSort}
                />

                <TableBody>
                    {loading.value
                        ? <LinearProgressView />
                        : filteredCommissions
                            .slice(
                                table.page * table.rowsPerPage,
                                table.page * table.rowsPerPage + table.rowsPerPage,
                            )
                            .map((commission) => <MarketCommissionRow key={commission.id}
                                                                      commission={commission} />)}
                </TableBody>
            </Table>
        </TableContainer>
        <TablePaginationCustom
            count={filteredCommissions.length}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            dense={table.dense}
            onChangeDense={table.onChangeDense}
            labelRowsPerPage="Sayfa başına satır:"
        />
    </>
}