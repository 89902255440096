import React from 'react'
import { UserAddress } from '../../service/types/user'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { fDateTime } from '../../utils/format-time'

type Props = {
    address: UserAddress
}
export default function AddressItemView({ address }: Props) {
    return <TableRow>
        <TableCell>{address.title}</TableCell>
        <TableCell>{address.address}</TableCell>
        <TableCell>{fDateTime(address.createdAt)}</TableCell>
    </TableRow>
}