import DialogTitle from '@mui/material/DialogTitle'
import Iconify from '../../iconify'
import React from 'react'
import Box, { BoxProps } from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { useResponsive } from '../../../hooks/use-responsive'

type Props = BoxProps & {
    title: string
    icon?: string
    onClose: VoidFunction
}

export function CustomDialogTitle({ icon, sx, title, onClose }: Props) {
    const mdDown = useResponsive('down', 'md')

    return (
        <Box
            id="draggable-dialog-title"
            sx={{
                borderWidth: 1,
                cursor: mdDown ? '' : 'move',
                borderStyle: 'dashed',
                borderColor: 'transparent',
                backgroundColor: (theme) => theme.palette.grey[theme.palette.mode === 'light' ? 100 : 800],
                borderBottomColor: (theme) => theme.palette.divider,
                ...sx,
            }}
        >
            <DialogTitle sx={{ p: 1 }}>
                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                        {icon && (
                            <Iconify color={'text.secondary'} icon={icon} width={24} style={{ marginRight: '5px' }} />
                        )}
                        <Typography color={'text.secondary'} variant={'subtitle1'}>
                            {title}
                        </Typography>
                    </Box>
                    <IconButton onClick={onClose}>
                        <Iconify icon={'mingcute:close-fill'} />
                    </IconButton>
                </Stack>
            </DialogTitle>
        </Box>
    )
}
