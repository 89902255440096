export enum MarketType {
    branch = 'BRANCH',
    company = 'COMPANY',
}

export type MarketCommission = {
    id: string;
    name: string;
    commission: number;
    orders: number;
    revenue: number;
    cost: number;
    profit: number;
    marketType: MarketType;
};

export type IyzicoPayment = {
    id: string;
    name: string;
    amount: number;
    orders: number;
    marketType: MarketType;
};

export type IyzicoPaymentDetail = {
    amount: number;
    orderId: string;
    paymentId: string;
    paymentDate: number;
};