import {useCustomerService} from '../../service/account-service'
import {useRecordContext} from 'react-admin'
import {TableNoData, TablePaginationCustom, useTable} from '../../components/table'
import {useBoolean} from '../../hooks/use-boolean'
import React from 'react'
import {UserAddress} from '../../service/types/user'
import {useSnackbar} from 'notistack'
import TableContainer from '@mui/material/TableContainer'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Table from '@mui/material/Table'
import TableHeadCustom from '../../components/table/table-head-custom'
import TableBody from '@mui/material/TableBody'
import AddressItemView from './address-item-view'
import LinearProgressView from '../../common/linear-progress-view'

export default function UserAddressesView() {
    const customerService = useCustomerService()
    const record = useRecordContext()
    const table = useTable()
    const loading = useBoolean(true)
    const [addresses, setAddresses] = React.useState<UserAddress[]>([])
    const { enqueueSnackbar } = useSnackbar()
    React.useEffect(() => {
        const fetch = async () => {
            loading.setValue(true)
            await customerService.getAddresses(record.id as string)
                .then(setAddresses)
                .catch(() => {
                    enqueueSnackbar('Adresler yüklenirken bir hata oluştu', { variant: 'error' })
                    setAddresses([])
                }).finally(loading.onFalse)
        }
        void fetch()
    }, [record.id])

    return <>
        <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <Scrollbar>
                <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                    <TableHeadCustom
                        order={table.order}
                        orderBy={table.orderBy}
                        headLabel={[{
                            id: 'title',
                            label: 'Başlık',
                        }, {
                            id: 'address',
                            label: 'Adres',
                        }, {
                            id: 'createdAt',
                            label: 'Oluşturulma Tarihi',
                        }]}
                        rowCount={addresses.length}
                        numSelected={table.selected.length}
                        onSort={table.onSort}
                    />

                    <TableBody>
                        {loading.value
                            ? <LinearProgressView />
                            : addresses
                                .slice(
                                    table.page * table.rowsPerPage,
                                    table.page * table.rowsPerPage + table.rowsPerPage,
                                )
                                .map((row) => <AddressItemView key={row.id} address={row} />)}
                        <TableNoData notFound={addresses.length === 0 && !loading.value} />
                    </TableBody>
                </Table>
            </Scrollbar>
        </TableContainer>
        <TablePaginationCustom
            count={addresses.length}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            dense={table.dense}
            onChangeDense={table.onChangeDense}
            labelRowsPerPage="Satır"
        />
    </>
}