import React from 'react'
import Iconify from '../../components/iconify'
import Box, { BoxProps } from '@mui/material/Box'

type Props = {
    isDragging: boolean
} & BoxProps
export const DragHandle = ({ isDragging, ...others }: Props) => {
    return (
        <Box sx={{
            display: 'inline-block',
            verticalAlign: 'bottom',
            marginRight: '0.5rem',
            cursor: isDragging ? 'grabbing' : 'grab',
            width: 30,
            height: 30,
            alignItems: 'center',
            justifyContent: 'center',
        }} {...others}>
            <Iconify icon={'mdi:drag'} sx={{ width: 30, height: 30 }} />
        </Box>
    )
}
