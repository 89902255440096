import React, { useState } from 'react'
import { fetchJson } from '../rest/fetch'
import { useFormControl } from '@mui/material'
import { useController } from 'react-hook-form'
import Stack from '@mui/material/Stack'
import Iconify from '../components/iconify'
import Box from '@mui/material/Box'
import Placeholder from '../images/placeholder.png'
import { LoadingButton } from '@mui/lab'
import Dialog from '@mui/material/Dialog'
import { CustomDialogTitle } from '../components/custom-dialog/title/custom-dialog-title'
import DialogContent from '@mui/material/DialogContent'
import { useBoolean } from '../hooks/use-boolean'
import Typography from '@mui/material/Typography'
import { globalConfig } from '../global-config'

export default function ImageUploadInput({ source, required, sx }) {
    const [uploading, setUploading] = useState(false)
    const control = useFormControl()
    const inputRef = React.useRef(null)
    const dialogOpen = useBoolean(false)
    const onDrop = (event) => {
        const files = event.target.files
        if (files !== undefined && files.length > 0) {
            upload(files[0])
        }
    }
    const { field } = useController({
        source,
        control,
        name: source,
        rules: { required: required },
    })

    const upload = (file) => {
        setUploading(true)
        const formData = new FormData()
        formData.append('file', file)
        const url = globalConfig.api.base + '/upload/uploadImage'

        fetchJson(url, { body: formData, method: 'POST' })
            .then(({ body }) => {
                try {
                    const file = JSON.parse(body)
                    field.onChange(file.url)
                } catch (e) {
                }
            })
            .catch(() => {
                //ignore
            })
            .finally(() => {
                setUploading(false)
            })
    }

    const onRemove = () => {
        let fileUrl = field.value
        field.onChange(null)
        let url = `${globalConfig.api.base}/upload/delete?url=${encodeURI(fileUrl)}`

        fetchJson(url, { method: 'DELETE' })
            .then(() => {
                //ignore
            })
            .catch(() => {
            })
    }
    const chooseFile = () => {
        inputRef.current.click()
    }
    const isSelected = field !== undefined && field.value !== null && field.value !== undefined

    return (
        <>
            <Stack sx={sx}>
                <Stack direction={'row'} spacing={2} alignItems={'center'}>
                    <Box height={60} width={60}
                         overflow={'hidden'}
                         borderRadius={1}
                         onClick={isSelected ? dialogOpen.onTrue : () => {
                         }}
                         sx={{ cursor: isSelected ? 'pointer' : 'default' }}
                         display={'flex'} justifyContent={'center'}
                         alignItems={'center'}>
                        <img alt={''} src={isSelected ? field.value : Placeholder} width={'100%'} height={'100%'} />
                    </Box>
                    <input type={'file'} ref={inputRef} onChange={onDrop} accept={'image/*'}
                           style={{ display: 'none' }} />
                    <LoadingButton onClick={isSelected ? onRemove : chooseFile}
                                   loading={uploading}
                                   sx={{ width: 100 }}
                                   startIcon={<Iconify
                                       icon={isSelected ? 'solar:gallery-remove-bold' : 'solar:upload-bold-duotone'} />}
                                   variant={'soft'} size={'medium'}
                                   color={isSelected ? 'error' : 'success'}>
                        {isSelected ? 'Sil' : 'Yükle'}
                    </LoadingButton>
                </Stack>
                {required && !isSelected &&
                    <Typography variant={'caption'} color={'error'}>Resim yüklemek zorunludur</Typography>}
            </Stack>
            {field.value && <ImageViewDialog url={field.value} open={dialogOpen.value} onClose={dialogOpen.onFalse} />}
        </>
    )
}

export const ImageUploadTranslation = {
    en: {
        uploadImage: {
            selectMessage: 'Drop image here, or click to select image',
            max: '5MB',
            uploading: 'Uploading Image...',
            remove: 'Remove',
        },
    },
    tr: {
        uploadImage: {
            selectMessage: 'Resmi buraya bırakın veya resim seçmek için tıklayın',
            max: '5MB',
            uploading: 'Resim Yükleniyor...',
            remove: 'Sil',
        },
    },
}

export const ImageViewDialog = ({ url, open, onClose }) => {
    // create dialog and put image in center
    return <Dialog open={open} onClose={onClose} maxWidth={'md'} maxHeight={'400'} fullWidth>
        <CustomDialogTitle title={'Resim'} onClose={onClose} />
        <DialogContent sx={{ p: 2 }}>
            <img alt={''} src={url} style={{ maxWidth: '100%', maxHeight: '100%' }} />
        </DialogContent>
    </Dialog>
}
