import { Theme } from '@mui/material/styles'

// ----------------------------------------------------------------------

export function stepper(theme: Theme) {
    return {
        MuiStepConnector: {
            styleOverrides: {
                line: {
                    borderColor: theme.palette.divider,
                },
            },
        },
    };
}
