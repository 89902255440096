import React from 'react'
import { TableCell, TableRow } from '@mui/material'
import { MarketCommission, MarketType } from '../../service/types/finance'
import Label from 'src/components/label'

type MarketCommissionRowProps = {
    commission: MarketCommission;
};
const MarketCommissionRow = ({ commission }: MarketCommissionRowProps) => {
    return (
        <TableRow>
            <TableCell>{commission.name}</TableCell>
            <TableCell>{commission.orders}</TableCell>
            <TableCell>{commission.revenue.toFixed(2)}</TableCell>
            <TableCell>{commission.cost.toFixed(2)}</TableCell>
            <TableCell>{commission.profit.toFixed(2)}</TableCell>
            <TableCell>{commission.commission.toFixed(2)}</TableCell>
            <TableCell><MarketTypeLabel marketType={commission.marketType} /></TableCell>
        </TableRow>
    )
}

export function MarketTypeLabel({ marketType }: { marketType: MarketType }) {
    return <Label variant={'soft'}
                  color={'default'}>{marketType === MarketType.branch ? 'Bayiler' : 'İşletmeler'}</Label>
}

export default MarketCommissionRow