import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import LoadingButton from '@mui/lab/LoadingButton'
import Stack from '@mui/material/Stack'
import FormProvider from 'src/components/hook-form'
import React, { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'
import { useLocation, useNavigate } from 'react-router-dom'
import RHFCode from '../components/hook-form/rhf-code'
import EmailInboxIcon from '../images/email-inbox-icon'
import { useAuthService } from '../service/auth-service'
import { useNotify } from 'ra-core'
import { useBoolean } from '../hooks/use-boolean'

type Props = {
    token: string
}

export default function LoginCodeView({ token }: Props) {
    const [error, setError] = useState('')
    const navigate = useNavigate()
    const location = useLocation()
    const notify = useNotify()
    const userService = useAuthService()
    const loading = useBoolean(false)
    const [resendRemaining, setResendRemaining] = useState(90)

    const NewPasswordSchema = Yup.object().shape({
        code: Yup.string().min(4, '').required(''),
    })

    const defaultValues = {
        code: '',
    }

    const methods = useForm({
        mode: 'onChange',
        resolver: yupResolver(NewPasswordSchema),
        defaultValues,
    })

    const {
        handleSubmit,
        formState: { isSubmitting },
    } = methods

    const onSubmit = handleSubmit(async (data) => {
        try {
            setError('')
            await userService.verify(token, data.code)
            navigate(location.state ? location.state.nextPathname : '/')
        } catch (error) {
            setError(error.message)
        }
    })

    const onResend = async () => {
        loading.onTrue()
        try {
            setError('')
            await userService.resendCode(token)
            notify('Onay kodu tekrar gönderildi.', { type: 'success' })
            setResendRemaining(90)
        } catch (error) {
            setError(error.message)
        } finally {
            loading.onFalse()
        }
    }
    useEffect(() => {
        if (resendRemaining === 0) {
            return
        }
        const interval = setInterval(() => {
            if (resendRemaining > 0) {
                setResendRemaining(resendRemaining - 1)
            }
        }, 1000)
        return () => {
            clearInterval(interval)
        }
    }, [resendRemaining])

    const renderForm = (
        <Stack spacing={3} alignItems="center">
            <RHFCode name="code" />

            <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting || loading.value}
            >
                Onayla
            </LoadingButton>

            <LoadingButton
                size="small"
                variant="soft"
                onClick={onResend}
                color={'warning'}
                disabled={resendRemaining !== 0}
                loading={isSubmitting || loading.value}
            >
                Onay Kodunu Tekrar Gönder{resendRemaining !== 0 ? ` (${resendRemaining})s` : ''}
            </LoadingButton>

        </Stack>
    )

    const renderHead = (
        <>
            <EmailInboxIcon sx={{ height: 96 }} />
            <Stack spacing={1} sx={{ my: 5 }}>
                <Typography variant="h3">{'Lütfen Girişi Onaylayın'}</Typography>

                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {'Gelen kutunuzdaki onay kodunu girerek hesabınızı onaylayabilirsiniz.'}
                </Typography>

                <Alert sx={{ mt: 2 }} severity="warning">
                    Eğer onay kodunuzu gelen kutunuzda bulamıyorsanız, lütfen spam ya da gereksiz e-postalar klasörünüzü
                    kontrol ediniz.
                </Alert>
                {error && <Alert severity="error">{error}</Alert>}
            </Stack>
        </>
    )

    return (
        <FormProvider methods={methods} onSubmit={onSubmit}>
            {renderHead}

            {renderForm}
        </FormProvider>
    )
}
