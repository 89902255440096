import React from 'react'
import { useBoolean } from 'src/hooks/use-boolean'
import { useRecordContext } from 'react-admin'

type Prop = {
    source: string
}
export default function PasswordField({ source }: Prop) {
    const record = useRecordContext()
    const dotted = useBoolean(true)

    if (record === undefined) {
        return <></>
    }

    if (dotted.value) {
        return (
            <span onClick={dotted.onToggle} style={{ cursor: 'pointer' }}>
                ●●●●●●●●
            </span>
        )
    }
    return (
        <span onClick={dotted.onToggle} style={{ cursor: 'pointer' }}>
            {record[source]}
        </span>
    )
}
