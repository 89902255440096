import { type AxiosRequestConfig } from 'axios'
import { LoggedUser } from './types/user'
import { TokenRefreshService } from './token-refresh-service'
import { globalConfig } from '../global-config'

export class ApiAuthProvider {
    public static readonly INSTANCE = new ApiAuthProvider()
    private readonly storageKey = 'nerde.admin'

    public setSession(user?: LoggedUser) {
        if (user) {
            localStorage.setItem(`${this.storageKey}.accessToken`, user.token)
            localStorage.setItem(`${this.storageKey}.user`, JSON.stringify(user))
            TokenRefreshService.INSTANCE.start()
        } else {
            localStorage.removeItem(`${this.storageKey}.accessToken`)
            localStorage.removeItem(`${this.storageKey}.user`)
            TokenRefreshService.INSTANCE.stop()
        }
    }

    public getSession(): LoggedUser | undefined {
        const userJson = localStorage.getItem(`${this.storageKey}.user`)
        const token = localStorage.getItem(`${this.storageKey}.accessToken`)
        if (userJson && token) {
            const user = JSON.parse(userJson)
            if (user !== null) {
                return user
            }
        }
        return undefined
    }

    public async applyAuthorizer(request: AxiosRequestConfig) {
        const token = localStorage.getItem(`${this.storageKey}.accessToken`)
        const headers = request.headers ?? {}
        if (token && token.length > 0) {
            headers.Authorization = `Bearer ${token}`
        }
        headers['X-API-KEY'] = globalConfig.api.apiKey
        request.headers = headers
    }

    public updateToken(token: string) {
        const user = this.getSession()
        if (user) {
            user.token = token
            this.setSession(user)
        }
    }

}
