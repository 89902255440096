import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import MarketOnlinePaymentTable from './market-online-payment-table'
import { useBoolean } from '../../../hooks/use-boolean'
import Stack from '@mui/material/Stack'
import CircularProgress from '@mui/material/CircularProgress'

type IyzicoPaymentsDialogProps = {
    open: boolean;
    onClose: () => void;
    start: number;
    end: number;
    marketId: string;
    title: string;
};

const IyzicoPaymentsDialog = ({ open, onClose, start, end, marketId, title }: IyzicoPaymentsDialogProps) => {
    const loading = useBoolean()
    return (
        <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
            <DialogTitle>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                    <span>{title} | Ödeme Detayları</span>
                    {loading.value && <CircularProgress size={24} />}
                </Stack>
            </DialogTitle>
            <DialogContent>
                <MarketOnlinePaymentTable start={start}
                                          onLoading={loading.setValue}
                                          end={end} marketId={marketId} />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Kapat</Button>
            </DialogActions>
        </Dialog>
    )
}

export default IyzicoPaymentsDialog