export const CategoryTranslations = {
    tr: {
        categories: {
            name: 'Katagoriler',
            fields: {
                id: '#',
                name: {
                    turkish: 'Türkçe Adı',
                    english: 'İngilizce Adı',
                },
                createdAt: 'Oluşturulma Tarihi',
            },
        },
    },
}