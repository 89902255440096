export const NotificationTranslations = {
    tr: {
        notifications: {
            name: 'Bildirimler',
            fields: {
                title: {
                    english: 'İngilizce Başlık',
                    turkish: 'Turkce başlık',
                },
                createdAt: 'Oluşturulma Tarihi',
                body: {
                    english: 'Bildirim mesajı ingilizce',
                    turkish: 'Bildirim mesajı turkce',
                },
            },
        },
    },
}
