import axios, { AxiosInstance } from 'axios'
import { ApiAuthProvider } from './api-auth-provider'
import { useMemo } from 'react'
import { globalConfig } from '../global-config'

export const useRestService = () => {
    const api = useMemo(() => {
        const api = createAxios()
        return { api }
    }, [])

    return api.api
}

export function createAxios(): AxiosInstance {
    const api = axios.create({ baseURL: globalConfig.api.base })
    api.interceptors.request.use(async (request) => {
        await ApiAuthProvider.INSTANCE.applyAuthorizer(request)
        request.headers['Accept-Language'] = 'tr'
        return request
    })

    api.interceptors.response.use(
        (response) => {
            return response
        }, (error) => {
            const response = error.response
            if (response && response.status >= 400) {
                const data = response.data
                let error: RestError
                if (data?.message) {
                    error = new RestError(data.message, response.status)
                } else {
                    error = new RestError(`Teknik bir aksaklık var! [${response.status}]`, response.status)
                }
                throw error
            } else {
                throw new RestError('Teknik bir aksaklık var, Lütfen internete bağlı olduğunuzdan emin olun', 500)
            }
        },
    )
    return api
}

export class RestError extends Error {
    readonly status: number

    constructor(message: string, status: number) {
        super(message)
        this.name = 'RestError'
        this.status = status
    }
}
