import { useCustomerService } from '../../service/account-service'
import { useRecordContext } from 'react-admin'
import { TableNoData, TablePaginationCustom, useTable } from '../../components/table'
import { useBoolean } from '../../hooks/use-boolean'
import React from 'react'
import { Order } from '../../service/types/user'
import { useSnackbar } from 'notistack'
import TableContainer from '@mui/material/TableContainer'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Table from '@mui/material/Table'
import TableHeadCustom from '../../components/table/table-head-custom'
import TableBody from '@mui/material/TableBody'
import OrderRow from './order-row'
import LinearProgressView from '../../common/linear-progress-view'

const orderHeadLabel = [
    {
        id: 'status',
        label: 'Durum',
    },
    {
        id: 'finalPrice',
        label: 'Toplam Fiyat',
    },
    {
        id: 'pendingTime',
        label: 'Oluşturulma Tarihi',
    },
    {
        id: 'paymentType',
        label: 'Ödeme Türü',
    },
]
export default function UserOrdersView() {
    const customerService = useCustomerService()
    const record = useRecordContext()
    const table = useTable()
    const loading = useBoolean(true)
    const [orders, setOrders] = React.useState<Order[]>([])
    const { enqueueSnackbar } = useSnackbar()
    React.useEffect(() => {
        const fetch = async () => {
            loading.setValue(true)
            await customerService.getOrders(record.id as string)
                .then(setOrders)
                .catch(() => {
                    enqueueSnackbar('Siparişler yüklenirken bir hata oluştu', { variant: 'error' })
                    setOrders([])
                }).finally(loading.onFalse)
        }
        void fetch()
    }, [record.id])

    return <>
        <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <Scrollbar>
                <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                    <TableHeadCustom
                        order={table.order}
                        orderBy={table.orderBy}
                        headLabel={orderHeadLabel}
                        rowCount={orders.length}
                        numSelected={table.selected.length}
                        onSort={table.onSort}
                    />

                    <TableBody>
                        {loading.value
                            ? <LinearProgressView />
                            : orders
                                .slice(
                                    table.page * table.rowsPerPage,
                                    table.page * table.rowsPerPage + table.rowsPerPage,
                                )
                                .map((row) => <OrderRow key={row.id} order={row} />)}

                        <TableNoData notFound={orders.length === 0 && !loading.value} />
                    </TableBody>
                </Table>
            </Scrollbar>
        </TableContainer>
        <TablePaginationCustom
            count={orders.length}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            dense={table.dense}
            onChangeDense={table.onChangeDense}
            labelRowsPerPage="Satır"
        />
    </>
}