import React from 'react'
import Icon from '@mui/icons-material/LocationCity'
import {
    BooleanField,
    BooleanInput,
    Create,
    Datagrid,
    Edit,
    EditButton,
    List,
    required,
    TabbedForm,
    TextField,
    TextInput,
} from 'react-admin'
import { MapInput } from '../../common/map/map-input'
import { ListPagination } from '../../common/list-pagination'

export const CityIcon = Icon

export const CityList = () => (
    <List sort={{ field: 'id', order: 'DESC' }} pagination={<ListPagination />} perPage={50}>
        <Datagrid bulkActionButtons={false}>
            <TextField source={'name'} />
            <BooleanField source={'active'} />
            <EditButton />
        </Datagrid>
    </List>
)
export const CityCreate = () => (
    <Create redirect={'list'}>
        <CreateOrEdit />
    </Create>
)
export const CityEdit = () => (
    <Edit redirect={'list'}>
        <CreateOrEdit />
    </Edit>
)

const CreateOrEdit = () => (
    <TabbedForm redirect={'list'} syncWithLocation={false}>
        <TabbedForm.Tab label={'resources.cities.fields.location'}>
            <MapInput source={'location'} required={false} />
        </TabbedForm.Tab>
        <TabbedForm.Tab label={'resources.cities.general'}>
            <TextInput source={'name'} validate={required()}  name={'name'}/>
            <BooleanInput source={'active'} name={'active'} />
        </TabbedForm.Tab>
    </TabbedForm>
)

export const CityTranslations = {
    tr: {
        cities: {
            name: 'Şehirler',
            general: 'Genel',
            fields: {
                name: 'Adı',
                location: 'Hizmet Bölgeleri',
                active: 'Aktif',
            },
        },
    },
}
