import isEqual from 'lodash/isEqual'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { SettingsValueProps } from '../types'
import { SettingsContext } from './settings-context'
import { useLocalStorage } from '../../../hooks/use-local-storage'
import { localStorageGetItem } from '../../../utils/storage-available'

const STORAGE_KEY = 'nerde.admin.settings'

type SettingsProviderProps = {
    children: React.ReactNode
    defaultSettings: SettingsValueProps
}

export function SettingsProvider({ children, defaultSettings }: SettingsProviderProps) {
    const { state, update, reset } = useLocalStorage(STORAGE_KEY, defaultSettings)

    const [openDrawer, setOpenDrawer] = useState(false)

    const isArabic = localStorageGetItem('i18nextLng') === 'ar'

    useEffect(() => {
        if (isArabic) {
            onChangeDirectionByLang('ar')
        }
    }, [isArabic])

    // Direction by lang
    const onChangeDirectionByLang = useCallback(
        (lang: string) => {
            update('themeDirection', lang === 'ar' ? 'rtl' : 'ltr')
        },
        [update]
    )

    // Drawer
    const onToggleDrawer = useCallback(() => {
        setOpenDrawer((prev) => !prev)
    }, [])

    const onCloseDrawer = useCallback(() => {
        setOpenDrawer(false)
    }, [])

    const canReset = !isEqual(state, defaultSettings)

    const memoizedValue = useMemo(
        () => ({
            ...state,
            onUpdate: update,
            // Direction
            onChangeDirectionByLang,
            // Reset
            canReset,
            onReset: reset,
            // Drawer
            open: openDrawer,
            onToggle: onToggleDrawer,
            onClose: onCloseDrawer,
        }),
        [reset, update, state, canReset, openDrawer, onCloseDrawer, onToggleDrawer, onChangeDirectionByLang]
    )

    return <SettingsContext.Provider value={memoizedValue}>{children}</SettingsContext.Provider>
}
