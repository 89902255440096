import {
    BooleanInput,
    ChipField,
    Create,
    Datagrid,
    Edit,
    EditButton,
    email,
    Filter,
    List,
    NumberField,
    NumberInput,
    ReferenceArrayField,
    ReferenceArrayInput,
    ReferenceField,
    ReferenceInput,
    required,
    SelectArrayInput,
    SelectInput,
    SimpleForm,
    SingleFieldList,
    TextField,
    TextInput,
} from 'react-admin'
import React, { useEffect } from 'react'
import { ListPagination } from '../../common/list-pagination'
import PasswordField from '../../common/password-field'
import Icon from '@mui/icons-material/Storefront'
import { useFormContext, useWatch } from 'react-hook-form'
import Grid from '@mui/material/Grid'
import { ShowMarketButton } from '../../common/show-market-button'
import ImageUploadInput from '../../common/image-upload-input'
import SaveOnlyToolbar from '../../common/save-only-toolbar'

export const BranchIcon = Icon

const EditForm = () => <SimpleForm toolbar={<SaveOnlyToolbar />}>
    <Grid container columnSpacing={2} rowSpacing={1}>
        <Grid item xs={12} md={3} xl={2}>
            <TextInput fullWidth name={'username'}
                       helperText={'Market giriş yaparken kullanılacak. Lutfen dikkatli ve dogru e-posta giriniz.'}
                       source={'username'} validate={[required(), email()]} />
        </Grid>
        <Grid item md={9} xl={10}>

        </Grid>
        <Grid item xs={12} md={3} xl={2}>
            <TextInput fullWidth name={'name'} source={'name'} validate={required()} />
        </Grid>
        <Grid item xs={12} md={3} xl={2}>
            <NumberInput fullWidth name={'commission'} source={'commission'} validate={required()} />
        </Grid>
        <Grid item md={6} xl={8}>

        </Grid>
        <Grid item xs={12} md={3} xl={2}>
            <ReferenceInput name={'city'} source={'city'} reference={'cities'}>
                <SelectInput sx={{ marginTop: 0 }} fullWidth optionText={'name'} source={'id'}
                             validate={required()} />
            </ReferenceInput>
        </Grid>
        <Grid item xs={12} md={3} xl={2}>
            <DistrictInput />
        </Grid>
        <Grid item xs={12} md={12} xl={12}>
            <TownInput />
        </Grid>
        <Grid item xs={12} md={3} xl={2}>
            <TextInput fullWidth name={'address'} source={'address'} validate={required()} />
        </Grid>
        <Grid item xs={12} md={3} xl={2}>
            <TextInput fullWidth name={'phoneNumber'} source={'phoneNumber'} validate={required()} />
        </Grid>
        <Grid item md={6} xl={8}>
        </Grid>
        <Grid item md={12} xs={12} xl={12}>
            <ReferenceArrayInput name={'paymentTypes'} source={'paymentTypes'}
                                 perPage={100000}
                                 reference={'payment-types'}>
                <SelectArrayInput name={'paymentTypes'}
                                  fullWidth
                                  label={'Ödeme Tipleri'}
                                  validate={required()}
                                  source={'paymentTypes'} optionText={'name'} />
            </ReferenceArrayInput>
        </Grid>
        <Grid item md={12} xl={12}>
            <ImageUploadInput source={'image'} sx={{ pb: 1 }} required={true} />
        </Grid>

        <Grid item xs={12} md={3} xl={2}>
            <BooleanInput source={'enabled'} name={'enabled'}
                          helperText={'Off oldu zamanda, Market sistemi giriş yapamaz'} />
        </Grid>
    </Grid>
</SimpleForm>
export const BranchCreate = () => {
    return <Create redirect={'list'}>
        <EditForm />
    </Create>
}
export const BranchEdit = () => {
    return <Edit redirect={'list'}>
        <EditForm />
    </Edit>
}

const BranchFilter = (props: any) => (
    <Filter {...props}>
        <TextInput name={'name'} source={'name'} alwaysOn />
        <TextInput name={'username'} source={'username'} />
        <TextInput name={'phoneNumber'} source={'phoneNumber'} />
        <ReferenceInput name={'city'} source={'city'} reference={'cities'}>
            <SelectInput optionText={'name'} />
        </ReferenceInput>
    </Filter>
)

export const BranchList = () => {
    return <List sort={{ field: 'id', order: 'DESC' }} filters={<BranchFilter />} pagination={<ListPagination />}
                 perPage={50}>
        <Datagrid bulkActionButtons={false}>
            <TextField source={'name'} />
            <TextField source={'username'} />
            <PasswordField source={'password'} />
            <NumberField source={'commission'} />
            <TextField source={'phoneNumber'} />
            <TextField source={'address'} />
            <ReferenceArrayField reference={'payment-types'} source={'paymentTypes'}>
                <SingleFieldList>
                    <ChipField source={'name'} />
                </SingleFieldList>
            </ReferenceArrayField>
            <ReferenceField reference={'cities'} source={'city'}>
                <TextField source={'name'} />
            </ReferenceField>
            <ReferenceArrayField reference={'districts'} source={'districts'}>
                <SingleFieldList>
                    <ChipField source={'name'} />
                </SingleFieldList>
            </ReferenceArrayField>
            <ReferenceArrayField reference={'towns'} source={'towns'}>
                <SingleFieldList>
                    <ChipField source={'name'} />
                </SingleFieldList>
            </ReferenceArrayField>
            <ShowMarketButton />
            <EditButton />
        </Datagrid>
    </List>
}


const DistrictInput = () => {
    const city = useWatch({ 'name': 'city' })
    const { resetField, formState: { dirtyFields } } = useFormContext()
    useEffect(() => {
        const isDirty = dirtyFields.city !== undefined
        if (isDirty) {
            resetField('districts', { defaultValue: [] })
        }
    }, [city])
    return <ReferenceArrayInput name={'districts'} source={'districts'}
                                filter={{ city: { id: city } }}
                                perPage={100000}
                                reference={'districts'}>
        <SelectArrayInput name={'districts'}
                          disabled={!city}
                          error={!city}
                          fullWidth
                          validate={required()}
                          helperText={!city ? 'Önce şehir seçmek lazım' : ''}
                          source={'districts'} optionText={'name'} />
    </ReferenceArrayInput>
}

const TownInput = () => {
    const districts: number[] = useWatch({ 'name': 'districts' })
    const { resetField, formState: { dirtyFields } } = useFormContext()
    useEffect(() => {
        const isDirty = dirtyFields.districts !== undefined
        if (isDirty) {
            resetField('towns', { defaultValue: [] })
        }
    }, [districts])
    return <ReferenceArrayInput name={'towns'} source={'towns'}
                                perPage={100000}
                                filter={districts !== undefined ? {
                                    district: districts.map(it => ({ id: it })),
                                } : undefined}
                                reference={'towns'}>
        <SelectArrayInput name={'towns'}
                          disabled={districts === undefined || districts.length === 0}
                          error={districts === undefined || districts.length === 0}
                          fullWidth
                          validate={required()}
                          helperText={districts === undefined || districts.length === 0 ? 'Önce ilçe seçmek lazım' : ''}
                          source={'towns'} optionText={'name'} />
    </ReferenceArrayInput>
}
